/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { Typography, Divider, Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

export default function PrivacyPolicy(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Row style={{display: 'flex', justifyContent:'center', margin: '20px'}}>
            <Col span={18}md={18} xs={24}>
                <Card title='Incite Tube' color='red'>
                    <Title level={2}>Privacy Policy</Title>
                    <Text strong>Last updated: Feb, 23 2024</Text>

                <Divider />

                <Title level={3}>Introduction</Title>
                    <Text>
                    This Privacy Policy serves as a comprehensive guide outlining the principles and practices governing the gathering, utilization, and sharing of Your personal information in connection with your usage of our service. Its purpose is to inform you about your rights to privacy and the legal safeguards in place to protect those rights. Through this document, we aim to provide you with transparency and clarity regarding the handling of your data, ensuring you understand how we handle your information and the measures we take to safeguard your privacy in accordance with applicable laws and regulations.
                    </Text>
                <Divider />

                <Title level={3}>Interpretation and Definitions</Title>

                <Title level={4}>Interpretation</Title>
                <Text>
                The capitalized terms used in this document have specific definitions outlined under the following conditions. These definitions shall apply uniformly, regardless of whether the terms are used in singular or plural form.                    </Text>

                    <Title level={4}>Definitions</Title>
                    <Row gutter={24}>
                        <ul>
                            <li>An "Account" refers to a distinct user account established for your use in accessing our Service or specific portions thereof.</li>
                            <li>An "Affiliate Chapter" refers to an entity that either exercises control over, is controlled by, or is under common control with another party. In this context, "control" signifies the possession of at least 50% or more of the shares, equity interests, or other securities that entitle the holder to vote for the appointment of directors or other governing bodies.</li>
                            <li>The term "Company" (referred to interchangeably as "the Company," "We," "Us," or "Our" in this Agreement) pertains to InciteTube.</li>
                            <li>Cookies are tiny files that a website deposits on your computer, mobile device, or any other device. These files contain information about your browsing history on that particular website, among their various purposes.</li>
                            <li>Country: Means to: Globally</li><li>Device signifies any tool or gadget capable of accessing the Service, such as a computer, cellphone, or digital tablet.</li>
                            <li>Personal Data encompasses any information that is unique and specific to an identified or identifiable individual.</li>
                            <li>Service refers to the Website itself.</li>
                            <li>Service Provider refers to any natural or legal person who processes data on behalf of the Company. This includes third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Company, or assist the Company in analyzing how the Service is utilized.</li>
                            <li>Third-party Social Media Service denotes any website or social network through which a user can log in or create an account to access the Service.</li>
                            <li>Operation Data is the data that is collected automatically, either generated by the use of the Service or from the structure of the service itself (for illustration, the duration of a runner visit).</li>
                            <li>Website refers to InciteTube, you can access the website  
                                <Link to={'/'} target="_blank"> InciteTube</Link>.
                            </li>
                            <li>This refers to the individual accessing or using the Service, or the company, or any other legal entity on whose behalf such individual is accessing or using the Service, as applicable.</li>
                        </ul>
                    </Row>

                    <Divider />

                    <Row>
                        <Title level={3}>What personal data we collect from users, and Where it is used?</Title>
                        <Title level={4}>Types of Data Collected</Title>
                        <Title level={5}>Personal Data</Title>
                        
                        <Text>While using Our Service, we may request that You provide Us with certain identifiable information that can be used to communicate or identify You. This personally identifiable information may include, but is not limited to:
                        </Text>
                    </Row>

                    <Row>
                        <Title level={5}>Usage Data</Title>
                        
                        <Text>Usage Data is automatically collected when using the Service.</Text>
                        <Text>
                        Operation Data may encompass information akin to Your Device's Internet Protocol address (e.g., IP address), browser type, browsing activity, the pages of our Service that You visit, the date and time of Your visit, the duration of time spent on those pages, unique device identifiers, and other individual data.
                        </Text>

                        <Text>
                        We may also collect information that your browser sends whenever you visit our Service or access it through a mobile device.
                        </Text>

                        <Text>
                        You may also have the option of participating in fresh information with the Company 
                        through Your Third-Party Social Media Services account. However, during enrollment or else, 
                        you’re giving authorization to the company to use, 
                        If You choose to give similar information and Personal Data.
                        </Text>

                    </Row>
                    <Row>
                        <Title level={4}>Use of Tracking Technologies and Cookies</Title>
                        <Text>
                        
                        Use of Tracking Technologies and Cookies:
                        We utilize Cookies and similar tracking technologies to monitor activity on Our Service and store specific information. These tracking technologies may include beacons, tags, and scripts, which are employed to gather and track information, as well as to enhance and analyze Our Service. The technologies We employ may include:
                        </Text>
                        <ul>
                            <li>
                                Cookies or Browser Cookies: A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if You do not accept Cookies, then You may not be able to access and use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
                            </li>
                            <li>
                                Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
                            </li>
                        </ul>
                    </Row>

                    <Divider />

                    <Row>
                        <Title level={3}>Use of Your Personal Data</Title>
                        <Title level={4}>The Company may use Personal Data for the following purposes:
                        </Title>
                        <ul>
                            <li>To provide and maintain our Service, including monitoring the usage of our Service.</li>
                            <li>To Manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                            <li>For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.</li>
                            <li>To Contact You: To contact You by email, phone calls, text message, or other similar forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                            <li>To provide you with the latest news, special offers, and general information about other goods, services, and events which we offer that are similar to the ones that you have already purchased or inquired about unless You have opted not to receive such information.</li>
                            <li>To manage Your requests: To attend and manage Your requests to Us.</li>
                            <li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
                            <li>For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, marketing, and your experience.</li>
                        </ul>

                    </Row>

                    <Row>
                        <Title level={3}>Children's Privacy</Title>
                        <Text>
                            Our Service is are childrenof all ages. We don't deliberately collect any  identifiable information from anyone. Still, please communicate with Us, If You're a parent or guardian and You're apprehensive that Your child has handed Us particular data. However, we have ways to remove that information from Our waiters, If We come apprehensive that We've collected Personal Data from anyone under the age of 13 without verification of maternal concurrence.
                        </Text>
                        <Text>
                            If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, we may require Your parent's consent before We collect and use that information.
                        </Text>
                    </Row>
                    <Row>
                        <Title level={4}>Changes to this Privacy Policy</Title>
                        
                    <Text>
                        We may amend and update Our sequestration Policy from time to time. We'll notify You of any changes by posting the new sequestration Policy on this runner.
                    </Text>
                    <Text>
                        We'll let You know via dispatch and/ or a prominent notice on Our Service before the change gets effective and modernize the " Last streamlined " date at the top of this sequestration Policy.
                    </Text>
                    <Text>
                        You're advised to review this sequestration Policy from time to time for any changes. Changes to this sequestration Policy are effective as soon as they're posted on this runner.
                    </Text>

                    </Row>
                </Card>
            </Col>
        </Row>
    );
};