import React, { useState } from 'react';
import {  Card, Col, Divider, Row, Typography } from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

// const gridStyle: React.CSSProperties = {
//     width: '23%',
//     textAlign: 'center',
//     borderRadius: '5px'
//   };

interface ChildProps {
  data: any;
  onEpisodeChange: (url: string) => void;
}

export default function Episodes({
  data,
  onEpisodeChange
}: ChildProps) {
  const [selectedEpisode, setSelectedEpisode] = useState<number>(0);
  const matches = useMediaQuery('(min-width: 768px)');

  const updateIframeURL = (url: string, index: number) => {
    setSelectedEpisode(index);
    onEpisodeChange(url);
  }

  return (
    <>
        <Card 
        title={data?.title}
        className='episodes'
        >
          <Card.Meta
          style={{paddingLeft:'20px'}}           
          title={<Typography.Paragraph 
            ellipsis={{ rows: 3, expandable: true }}>{data?.description}</Typography.Paragraph>
        }
          />
          <Divider></Divider>
          <Row gutter={24} >
          {data?.episodes?.map((url: any, index: number)=> {
          return <Col key={index} span={6} xs={matches ? 24: 6} sm={matches ? 6: 12}>
              <p style={{ backgroundColor: selectedEpisode === index ? '#001529' : '' }} key={index} className={matches ? 'grid-style': 'grid-style-mobile'} 
                    onClick={() => updateIframeURL(url,index)}
              >
              {`Episode ${data?.episodes?.length - index}`}
              </p>
          </Col>
          })}
          </Row>
        </Card>
        {/* {loader ? <Loader /> : <></>} */}
    </>
  );
};