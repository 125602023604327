import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import { styles } from '../../styles';
import { DynammicTalksArray, talksInterface } from '../interfaces';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

export default function Talks({data}: DynammicTalksArray) {
    const matches = useMediaQuery('(min-width: 768px)');

    return (
        <Row gutter={24}>
            {
                data?.videos?.map((video: talksInterface, index: number)=> {
                    return <Col key={index} span={matches ? data?.spanSize: 8}  xs={matches ? 24: 8} sm={matches ? data?.spanSize: 8} style={styles.card}>
                        <Link to={`/videos/${video?.category}/${video?.id}`}>
                        <div className='video-container'>
                            <Tooltip title={`Watch ${video?.title}`}>
                                <img
                                    alt={video?.title}
                                    src={video?.thumbnail}
                                    style={matches ? styles.movieIimage: {...styles.movieIimage, minHeight:'65px'}}
                                    className='movie-image'
                                />
                            </Tooltip>
                            {<div className='episode-icon'>
                                {video?.episodes?.length > 0 ? `EP ${video?.episodes?.length}`: 'HD'}
                            </div>}
                            <div className='play-button'></div>
                        </div>
                        </Link>
                    </Col>
                })
            }
        </Row>
    );
};