import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Pagination } from 'antd';
import { talkTypes } from '../../utils/constant';
import Talks from '../Talks';
import { talkDataInterface } from '../interfaces';
import { useLocation, useParams } from 'react-router-dom';
import TalkCategories from '../TalkCategories';
import Loader from '../../components/common/Loader';
import { getMovies, getShows } from '../../apis/fixture.api';
import SearchBar from '../../components/common/Search';
import { styles } from '../../styles';
import { getCategoriesForTitle } from '../../utils/getCategories';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

export default function CategoryTalks() {
    const { categoryName, subCategoryName } = useParams();
    const [talks, setTalks] = useState<talkDataInterface>();
    const [relatedTalks, setRelatedTalks] = useState<talkDataInterface>();
    const [loader, setLoader] = useState<boolean>(false);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get('search');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(40);
    const [totalMovies, setTotalMovies] = useState<number>(0);
    const matches = useMediaQuery('(min-width: 768px)');

    const createCategoryTalks = async () => {
        setLoader(true);
        const subCategories = categoryName ? getCategoriesForTitle(categoryName): [];
        const filter = subCategoryName ? [subCategoryName]: categoryName ? subCategories: [];
        const response = categoryName != 'tv-shows'? 
        await getMovies({ 
            type: categoryName, 
            category: filter, 
            searchTerm: searchTerm,
            currentPage: currentPage,
            pageSize: pageSize 
        }) 
        :await getShows({ 
            type: categoryName, 
            category: filter, 
            searchTerm: searchTerm,
            currentPage: currentPage,
            pageSize: pageSize  
        });
        const allTalks = response?.data?.movies;
        setTalks({
            videos: allTalks,
            spanSize: categoryName == 'Trailers'? talkTypes.searchVideos.spanSize: talkTypes.categoryTalks.spanSize,
        });
        setRelatedTalks({
            videos: response?.data?.relatedTalks,
            spanSize: talkTypes.categoryTalks.spanSize,
        });
        setTotalMovies(response?.data?.total ?? response?.data?.relatedTalks?.length);
        setLoader(false);
    }

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        createCategoryTalks();
      }, [categoryName, search, subCategoryName, currentPage]);

      useEffect(() => {
        setCurrentPage(1);
      }, [categoryName]);

    return (
        <>
            {!matches && <Row style={{...styles.searchBarContainer, paddingBottom: '10px', paddingTop: '10px'}}>
                <Col span={12}>
                    <SearchBar/>
                </Col>
            </Row>}
            <TalkCategories categoryName={categoryName} subCategoryName={subCategoryName}></TalkCategories>
            <Row gutter={24} style={{display: 'flex', justifyContent: 'center', paddingBottom:'10px'}}>
                <Col span={20}>
                    <Typography.Title 
                    level={matches ? 3: 5} 
                    style={{ marginBottom: '20px'}}>
                    {categoryName?.toUpperCase() ?? <p>Search results for <span style={{color:'#001529'}}>{searchTerm}</span></p>}
                    </Typography.Title>
                    {talks?.videos?.length ? <Talks data={talks}></Talks>: 
                    loader ? <></>: <Typography.Text style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>No Video Found</Typography.Text>}
                    {relatedTalks?.videos?.length && <Typography.Title 
                    level={matches ? 3: 5}
                    style={{ marginLeft: '5px'}}
                    >May be you like
                    </Typography.Title>}
                    {relatedTalks?.videos?.length ? <Talks data={relatedTalks}></Talks>: <></>}
                </Col>
            </Row>
            <Row gutter={24}style={{display: 'flex', justifyContent:'center', margin: '10px'}}>
                <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalMovies}
                onChange={onPageChange}
                showSizeChanger={false} // Hide the option to change page size
                />
            </Row>
            {loader ? <Loader /> : <></>}
        </>
    );
};